import React from 'react';
import { Button } from 'freemium-ui';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import CheckForm from '../containers/CheckForm';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import UserBadgeDropdown from './UserBadgeDropdown';
import { Notification } from './Icons';
import {
  getCurrentUser,
  goOffline,
  getCurrentUserOrg,
  setFilterState,
  getEdgeLocations,
  toggleCheckDrawer,
  getOrgApplications,
  setBanner,
  getAppMembers,
  getAppSubscribers,
  getBadges
} from '../actions';
import Drawer from 'shared/components/Drawer';
import '../styles/Header.css';
import { getOrgId, datewithTimezone } from '../utils/utility';
import CaptureInterest from '../components/CaptureInterst';
import WelcomeModal from '../components/WelcomeModal';
import OnboardingModal from '../components/OnboardingModal';
import Icons from './styled/Icons';
import { request } from '../utils/Api';
import Banner from './Banner';
import appConfig from '../config';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      org: this.props.org,
      number_of_checks: 0,
      maximum_checks_allowed: 0,
      interest: {
        open: false
      },
      banner: null
    };
  }

  componentDidMount() {
    if (this.props.user) {
      this.setBanner();
    }
    this.props.getCurrentUser();
    this.props.getOrgApplications();
    this.props.getEdgeLocations();
    this.props.getBadges();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.user !== prevProps.user ||
      this.props.badges.badges.count !== prevProps.badges.badges.count
    ) {
      this.setBanner();
    }
    if (this.props.appOrg && this.props.appOrg.applications) {
      if (this.props.appOrg.applications !== prevProps.appOrg.applications) {
        this.props.getApplicationMembers(
          this.props.appOrg.applications.results[0].id
        );
        this.props.getApplicationSubscribers(
          this.props.appOrg.applications.results[0].id
        );
      }
    }
  }
  updatePaymentMethod = async () => {
    const payload = {
      organization: getOrgId()
    };
    const payment = await request(`/update-payment-method/`, {
      method: 'POST',
      payload
    });

    if (payment.hosted_page_url) {
      window.location.href = payment.hosted_page_url;
    }
  };

  handleClick = () => {
    const currentOrg = this.props.org.find(
      org => org.id === Number(getOrgId())
    );
    const { number_of_checks, maximum_checks_allowed } = currentOrg;

    if (number_of_checks === maximum_checks_allowed) return false;

    this.setState({ show: true });
    this.props.goOffline();

    if (window.fcWidget) {
      window.fcWidget.hide();
    }
  };

  openCheckCreateDrawer() {
    if (window.fcWidget) {
      window.fcWidget.hide();
    }

    this.props.goOffline();
    this.props.toggleCheckDrawer({
      isOpen: true,
      check: null
    });
  }
  onClose = () => {
    this.props.toggleCheckDrawer({
      isOpen: false,
      check: null
    });
  };

  setBanner = () => {
    const orgId = Number(getOrgId());
    const { org } = this.state;
    const currentOrg = org && org.find(org => org.id === orgId);
    const user = this.props.user;
    const signupSuspensionHeader = JSON.parse(window.localStorage.getItem('signup-suspension-header'));
    if (!signupSuspensionHeader || !signupSuspensionHeader.find(each => each.orgId === orgId)) {
      this.setState({ ...this.state, banner: 'signup-suspension' });
      return;
    }
    if (
      user &&
      user.date_joined &&
      moment().diff(user.date_joined, 'days') >= 5
    ) {
      const exploreHeader = JSON.parse(
        window.localStorage.getItem('explore-header')
      );
      if (!exploreHeader || !exploreHeader.find(each => each.orgId === orgId)) {
        this.setState({ ...this.state, banner: 'explore' });
        return;
      }
    }
    const badgeHeader = JSON.parse(window.localStorage.getItem('badge-header'));
    if (
      currentOrg &&
      currentOrg.logged_in_user_role === 'AD' &&
      !this.props.badges.badges.count &&
      (!badgeHeader || !badgeHeader.find(each => each.orgId === orgId))
    ) {
      this.setState({ ...this.state, banner: 'badge' });
      return;
    }
    this.setState({ ...this.state, banner: null });
  };

  setFreshsuccess = (user, currentOrg, page) => {
    if (
      !window._na &&
      window.na &&
      appConfig.FRESHSUCCESS_API_KEY &&
      appConfig.FRESHSUCCESS_AUTH_KEY
    ) {
      window._na = new window.na(appConfig.FRESHSUCCESS_API_KEY, appConfig.FRESHSUCCESS_AUTH_KEY, {
        userId: user.id.toString(),
        accountId: currentOrg.id.toString(),
        trackUnload: true,
        euEndpoint: true // disable if not EU datacenter
        // debugUrl: "https://events-test-eu.freshsuccess.com/v1/66ee0364da1d8868605d6a6433ebad59/dqn29parrzqxumpvio3dt9p11uzpa4lo",
        // disableEventSend: false, // disable the sending of events
        // debug: true, // disable console debug prints
      });
      window._na.login(user.id.toString(), currentOrg.id.toString(), {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        phone: user.profile.phone_number
      });
      window._na.identifyUser({
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email
      });
      window._na.identifyAccount({
        name: currentOrg.name,
        join_date: user.date_joined,
        tier: currentOrg.subscription.plan_name
      });
    }

    if (window._na) {
      // set module
      let module = page;
      switch (page) {
        case 'Settings':
          const pathname = window.location.pathname;
          if (pathname.indexOf('/settings/users') > -1) {
            module = 'Settings > Users';
          } else if (pathname.indexOf('/settings/integrations') > -1) {
            module = 'Settings > Integrations';
          } else if (pathname.indexOf('/settings/account') > -1) {
            module = 'Settings > Account';
          } else if (pathname.indexOf('/settings/pricing') > -1) {
            module = 'Settings > Pricing';
          }
          break;
        default:
          break;
      }
      window._na.setModuleId(module);
      window._na_module = module;
    }
  };

  initSurveyWidget = (user, currentOrg) => {
    if (window.SurveyWidget) {
      if (appConfig.ENV === "production") {
        let contact = {
          "first_name": user.first_name,
          "last_name": user.last_name,
          "work_email": user.email,
          "designation": "",
          "address": "",
          "city": "",
          "state": "",
          "country": "",
          "zipcode": "",
          "language": "EN",
          "timezone": currentOrg.timezone,
          "industry": "",
          "company_name": currentOrg.name,
          "company_website": "",
          "last_emailed": "",
          "unsubscribe_time": "",
          "email_state": "",
          "product_role": user.role === "OW" ? "Owner" : user.role === "AD" ? "Admin" : "Read Only",
          "product_name": "FRESHPING",
          "product_signup_date": new Date(user.date_joined).toISOString(),
          "account_name": currentOrg.name,
          "account_url": currentOrg.freshid_accounts_name + ".freshping.io",
          "product_account_id": currentOrg.id.toString(),
          "plan": currentOrg.subscription.plan_name
        }
        
        window.SurveyWidget.init({
          type: "default",
          delay: 2,
          include_unsubscribe :true,
          survey_id: "5813dc44-62a1-4622-9778-82535d594d9e",
          survey_collector_id: "SC-111",
          contact: contact
        });
      } else if (
          localStorage.getItem('surveyId') !== '' &&
          localStorage.getItem('collectorId') !== ''
        ) {
        window.SurveyWidget.init({
          type: localStorage.getItem('widgetType') || 'default',
          delay: localStorage.getItem('delay') || 5,
          include_unsubscribe:
            localStorage.getItem('unsubscribe') === 'true' || true,
          survey_id: localStorage.getItem('surveyId'),
          survey_collector_id: localStorage.getItem('collectorId'),
          contact: {
            work_email: user.email
          }
        });
      }
    }
  };

  render() {
    const {
      isAdmin,
      app,
      appOrg,
      page,
      user,
      org,
      userOrgs,
      deviceSize
    } = this.props; //counter, isLoading,
    const orgId = Number(getOrgId());
    const currentOrg = org && org.find(org => org.id === orgId); //filter(org, { id: Number(getOrgId()) })[0];
    // const badgeHeader = JSON.parse(window.localStorage.getItem('badge-header'));
    // var showBadgeHeader = true;
    // if (badgeHeader) {
    //   for (var count = 0; count < badgeHeader.length; count++) {
    //     if (badgeHeader[count].orgId === orgId) {
    //       showBadgeHeader = false;
    //       break;
    //     }
    //   }
    // }
    let subscription = {},
      addCheckInfo = 0,
      trailRemaining = 0,
      suspensionDays = 0,
      trailend,
      isActive = true;
    if (currentOrg && currentOrg.subscription) {
      subscription = currentOrg.subscription;
      addCheckInfo = `${currentOrg.number_of_checks}/${currentOrg.maximum_checks_allowed}`;
      trailend = datewithTimezone(subscription.trial_end, currentOrg.timezone);
      trailRemaining =
        moment(trailend).diff(
          datewithTimezone(moment(), currentOrg.timezone),
          'days'
        ) + 1;
      suspensionDays =
        subscription.current_term_end &&
        datewithTimezone(
          moment(subscription.current_term_end).add(12, 'hours'),
          currentOrg.timezone
        ).diff(datewithTimezone(moment(), currentOrg.timezone), 'days', true) +
          14;
      isActive = subscription.status !== 'in_trial' && suspensionDays > 14;
    }
    const isExploreOrSettings =
      this.props.location.pathname.indexOf('explore') != -1 ||
      this.props.location.pathname.indexOf('settings') != -1;

    if (user && currentOrg) {
      this.setFreshsuccess(user, currentOrg, page);
      this.initSurveyWidget(user, currentOrg);
    }

    return (
      <header className={deviceSize + ' root-header'}>
        {this.state.banner && !isExploreOrSettings && (
          <Banner
            setBanner={this.setBanner}
            banner={this.state.banner}
            routerHistory={this.props.history}
          />
        )}
        {user && user.verification_required_before && (
          <div id="top-header" className="top-header">
            <span className="activation">
              Activation link sent to {user && user.email}. To get downtime
              alerts smoothly, please validate your email using the link sent.
            </span>
          </div>
        )}
        {subscription && subscription.card_status !== "no_card" && (
          <div id="top-header" className="top-header">
            <div className="activation">
              <div>
                Please update your billing/shipping address, and tax ID in the&nbsp;
                <Link to={`/settings/pricing`}>Pricing and Billing details</Link>.
                Additional information regarding tax rules for your Freshworks 
              </div>
              <div>
                subscription and your specific region can be found&nbsp;
                <a href="https://www.freshworks.com/company/sales-and-service-tax-faqs/" target="_blank">here</a>. 
                Please ignore if already updated.
              </div>
            </div>
          </div>
        )}
        {suspensionDays && !isActive && subscription.plan_name !== 'SPROUT' ? (
          <div id="top-header" className="top-header account-suspension">
            <Icons glyph="caution" size="16px" viewBox="0 0 22 19" color="#D7461F"/>
            <div className="activation">
              Your account will be suspended in {parseInt(suspensionDays)} days.
              Please update your card details to avoid ACCOUNT SUSPENSION.
            </div>
            <button onClick={() => this.updatePaymentMethod()}>
              Update card details
            </button>
          </div>
        ) : null}
        <div className="header">
          <div className="dashboard breadcrumb">
            <span className="breadcrumb-title">
              {page ? page : 'Dashboard'}
            </span>
          </div>
          {trailRemaining > 0 &&
          !subscription.current_term_end &&
          subscription.plan_name !== 'SPROUT' ? (
            <div className="trialdays">
              Your trial is expiring in <b>{trailRemaining} days</b>,{' '}
              <Link to={`/settings/pricing`}>Activate the plan</Link> to prevent
              suspension.{' '}
            </div>
          ) : null}
          <div className="page-navigation">
            {isAdmin && (
              <Button
                id="add-check"
                inline
                size="normal"
                type="secondary"
                onClick={this.openCheckCreateDrawer.bind(this)}
              >
                {`+ Add Check ${addCheckInfo}`}
              </Button>
            )}
            <button className="notification-btn" id="beamer-root">
              <Notification />
            </button>
            {user && currentOrg ? (
              <UserBadgeDropdown user={user} currentOrg={currentOrg} />
            ) : (
              <span className="userprofile-loader">
                <Skeleton width={30} />
              </span>
            )}
          </div>
        </div>
        <Drawer
          onClose={this.onClose}
          isOpen={app.checkDrawer.isOpen}
          width="800px"
        >
          {userOrgs &&
            appOrg.applications &&
            appOrg.applications.results &&
            appOrg.applicationMemebers &&
            appOrg.applicationSubscribers && (
              <CheckForm
                appMembers={appOrg.applicationMemebers}
                appSubscribers={appOrg.applicationSubscribers}
                modalClose={this.onClose}
              />
            )}
        </Drawer>

        <CaptureInterest />
        <WelcomeModal />
        {window.screen.width > 480 && <OnboardingModal />}
      </header>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.user,
  app: state.app,
  userOrgs: state.user.org,
  org: state.user.org,
  appOrg: state.org,
  badges: state.badges
});

const mapDispatchProps = dispatch => ({
  getCurrentUser: () => dispatch(getCurrentUser()),
  getCurrentUserOrg: () => dispatch(getCurrentUserOrg()),
  getOrgApplications: () => dispatch(getOrgApplications()),
  getEdgeLocations: () => dispatch(getEdgeLocations()),
  getApplicationMembers: payload => dispatch(getAppMembers(payload)),
  getApplicationSubscribers: payload => dispatch(getAppSubscribers(payload)),
  goOffline: filter => dispatch(goOffline(filter)),
  setFilterState: filter => dispatch(setFilterState(filter)),
  toggleCheckDrawer: state => dispatch(toggleCheckDrawer(state)),
  setBanner: payload => dispatch(setBanner(payload)),
  getBadges: () => dispatch(getBadges())
});

Header.contextTypes = {
  router: PropTypes.object.isRequired
};
export default withRouter(connect(mapStateToProps, mapDispatchProps)(Header));
